<template>
  <div>
    <form-section :title="$t('courierSet.prcelContent')">
      <v-row>
        <v-col>
          <select-field
            v-model="configurationData.pageFormat"
            :title="$t('courierSet.pageFormat')"
            rules="required"
            :filed-items="pageFormatItems"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="configurationData.fileFormat"
            :title="$t('courierSet.fileFormat')"
            rules="required"
            :filed-items="fileFormatItems"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="configurationData.referenceNumber"
            :title="$t('courierSet.referenceNumber')"
            rules="max:165"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="configurationData.allegroDefaultValues.description"
            :title="$t('courierSet.parcelDescription')"
            rules="required|max:165"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContentAndComments',
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    fileFormatItems: [
      { id: 0, name: 'PDF' },
      { id: 1, name: 'ZPL' },
      { id: 2, name: 'EPL' },
    ],
    pageFormatItems: [
      { id: 0, name: 'A4' },
      { id: 1, name: 'A6' },
    ],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    configurationData() {
      return this.getConfigurationSet();
    },
  },
  mounted() {
    if (this.isNew) {
      this.configurationData.pageFormat = 0;
      this.configurationData.fileFormat = 0;
    }
  },
};
</script>

<style scoped></style>
