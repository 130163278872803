import { CashOnDelivery } from './CashOnDelivery';
import { Insurance } from './Insurance';

export const AllegroDefaultValues = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  referenceNumber: '',
  description: '',
  cashOnDelivery: CashOnDelivery,
  insurance: Insurance,
};
