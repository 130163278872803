export const Services = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  serviceId: '',
  credentialsId: '',
  serviceCode: '',
  name: '',
  owner: '',
  carrierId: '',
  options: [],
};
