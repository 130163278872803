export const allegroAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Pobranie za przesyłkę.',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki',
    parameters: true,
    remark: '',
  },
];
